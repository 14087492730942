import { Component, inject, computed, WritableSignal, signal, AfterViewChecked, AfterViewInit, Signal } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AccountRecoveryService } from '../account-recovery.service';
import { NotificationService } from '../../../shared/notification/notification.service';
import { tap } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-check-code',
  standalone: true,
  imports: [FormsModule, RouterLink],
  templateUrl: './check-code.component.html',
})
export class CheckCodeComponent implements AfterViewInit {
  private accountRecoveryService = inject(AccountRecoveryService);
  private notificationService = inject(NotificationService);
  private router = inject(Router);
  #sanitizer = inject(DomSanitizer);

  code: WritableSignal<string> = signal('');
  email = computed(() => this.accountRecoveryService.email());
  mobile: Signal<string> = computed(() => this.accountRecoveryService.mobile());
  message: Signal<SafeHtml> = computed(() => this.#setupMessage());

  ngAfterViewInit(): void {
    if (!this.email()) {
      this.router.navigate(['/account-recovery/check-email']);
    }
  }

  checkCode() {
    if (!this.code()) return this.notificationService.show({ text: 'Please, enter de verification code', type: 'warning' });
    this.accountRecoveryService.verifyCode(this.code()).pipe(
      tap(() => this.router.navigate(['/account-recovery/new-password']))
    ).subscribe();
  }

  #setupMessage() {
    const mobile = this.mobile() || null;
    let message = `<h2>Check your ${mobile ? 'messages' : 'email'}</h2><p> A verification code has been sent to your email <strong>${this.email()}</strong>`;
    if (this.mobile())
      message += ` and to your registered phone number <strong>*** *** ${this.mobile()}</strong>`;
    message += `</p><p><strong> Please, check your inbox${mobile ? ' and your messages' : ''}.</strong> If you don’t see the email in your main inbox, <strong>check your spam or junk folder.</strong></p>`;
    return this.#sanitizer.bypassSecurityTrustHtml(message);
  }
}
