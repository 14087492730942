import { Component, OnInit, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  NgbAccordionBody,
  NgbAccordionButton,
  NgbAccordionCollapse,
  NgbAccordionDirective, NgbAccordionHeader, NgbAccordionItem
} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-faq',
  standalone: true,
  templateUrl: './faq.component.html',
  imports: [
    NgbAccordionBody,
    NgbAccordionButton,
    NgbAccordionCollapse,
    NgbAccordionDirective,
    NgbAccordionHeader,
    NgbAccordionItem
  ],
  styleUrl: './faq.component.scss'
})
export class FaqComponent implements OnInit {
  faq = [
    {
      question: 'I am a current Farmbox customer. How do I access the new website?',
      answer: `
      <p>In order to log into our new website, you’ll need to reset your password. When you go to login, follow the forgot password prompts. Once you login, you should find all your subscription preferences, payment information, delivery notes, and any credit balances pre-loaded into your account and be able to shop with us as usual. If you have any questions about your account or the new website, please contact our Customer Care Team at <a href="mailto:info@farmboxdl.com">info@farmboxdl.com</a> or 214-390-7417.</p>
      `
    },
    {
      question: 'Will my dislike selections be transferred to the new website?',
      answer: `<p>Yes, all your dislike selections will be transferred over. We highly recommend that you review them for accuracy and explore the new enhanced dislike features.</p>
      <p>You are now able to add both dislikes and favorites. When you have a dislike in your FarmBox, our system will automatically look for your favorites when picking an alternative item. To select your favorites or dislikes, simply click on the heart icon on the top left corner. Then click on the thumbs-up or heart icon. That’s it, you're set.</p>
      <img src="assets/images/landing/faq/1b.webp" alt="Screenshot" class="w-100" style="box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;" />`
    },
    {
      question: 'What makes shopping with FarmBox Delivery better than the supermarket or other delivery companies?',
      answer: '<p>Lots of things! First, the food that you buy from FarmBox Delivery is much fresher and higher quality than the food you’d find at your traditional grocery store or other delivery companies since it’s not sitting around waiting to be purchased or traveling across the state to get to you. We do not keep inventory, we only order what we need for the boxes going out that week. This allows us to only deliver the very freshest groceries to you.</p>' +
        '<p>With FarmBox Delivery, you can search for exactly what you want and find it right away—plus you can browse the aisles and discover new products. And our recipes give weekly inspiration so you’re never stuck trying to figure out what to make for dinner.</p>'
    },
    {
      question: 'How do I customize my FarmBox?',
      answer: `
        <ol>
          <li>
           <p>To access your FarmBox, click the shopping cart icon in the top right corner of the screen.</p>
            <img src="assets/images/landing/faq/1a.webp" alt="Screenshot" class="w-100" style="box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;"/>
          </li>
          <li class="mt-5">
           <p>In your shopping cart, you will see a list of your subscriptions on the right. Click on the "Customize Box" button.</p>
           <img src="assets/images/landing/faq/2a.webp"alt="Screenshot"  class="w-100" style="box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;"/>
          </li>
          <li class="mt-5">
           <p>You can customize what’s in your curated box and/or add other grocery items to your cart. Click the "Save Customizations" button after making your selections.</p>
           <img src="assets/images/landing/faq/3a.webp" alt="Screenshot" class="w-100" style="box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;"/>
          </li>
          <li class="mt-5">
           <p>Once you click the "Save Customizations" button, please confirm your order by clicking the "Update Order" button on the lower right corner.</p>
          </li>
        </ol>
      `
    },
    {
      question: 'How do I change my Farmbox selection?',
      answer: `
      <p>Your membership requires you to remain subscribed to at least one FarmBox. If you’d like to change your FarmBox selection, please complete <a rel="noopener" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLScBa__EF9rGfl88CdlL_I-NSAPtOrsKvLVLi_9AY-TTy6wmuw/viewform">THIS FORM</a> and we will make the necessary adjustments. A team member will reach out via email to confirm once your change has been processed.</p>
      <p><strong>Important:</strong> If you submit your request after 5 PM on Sunday and have an upcoming order that week, we will do our best to accommodate the change, but we cannot guarantee we will have all items in stock.</p>`
    },
    {
      question: 'How do I remove recurring items or subscriptions?',
      answer: `
      <p>To cancel your subscription, please complete <a rel="noopener" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSe_u-HvHUYPIGYfPcJcRfQhwAUqiXuIOw3bAfBfV2qyAoSolA/viewform">THIS FORM</a>. A team member will reach out via email to confirm once your cancellation has been processed.</p>
      <p><strong>Important:</strong> If you submit your cancellation request after 5 PM on Sunday and have an upcoming order that week, you will still be responsible for that order.</p>
      `
    },
    {
      question: 'When and how often will my groceries arrive?',
      answer: ` <p>We pick a delivery day for you based on your zip code. This allows us to deliver efficiently and reduce emissions. You can choose your deliveries to be scheduled weekly or biweekly. We start you out on a weekly subscription, and you can change the frequency after signing up by visiting the 'subscriptions' page.</p>`
    },
    {
      question: 'Can I skip a delivery?',
      answer: `
      <p>You can always skip a delivery if you’re headed out of town or are just drowning in produce (an enviable problem!). To skip:</p>
      <ol>
        <li>
          <p>Log in to your account.</p>
        </li>
        <li>
          <p>Head to your "account settings" in the top right corner.</p>
        </li>
        <li>
          <p>Locate the order you'd like to skip.</p>
        </li>
        <li>
          <p>Select the 'Pause Subscription’ link to the right of the delivery details.</p>
        </li>
      </ol>
      <img src="assets/images/landing/faq/1c.webp" alt="Screenshot" class="w-100" style="box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;"/></br>
      <img src="assets/images/landing/faq/2c.webp" alt="Screenshot" class="w-100 mt-5" style="box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;" />
      `
    },
    {
      question: 'When is the latest I can cancel or make changes to my order?',
      answer: `
        <p>We want your FarmBox groceries to fit easily into your life, which is why you can change or cancel your subscription without penalty. There's no contract or fine print to worry about. As long as you cancel before the end of the customization window for your upcoming order, you will not receive any further deliveries.</p>
        <p>In order to prevent food waste and keep our pledge to only offer the freshest food available, FarmBox Delivery does not keep an inventory from week to week. We only purchase what we need for the farm boxes going out that week. Therefore, we must have a cutoff time that gives us ample opportunity to purchase products from our local farmers and producers.</p>
        <p><strong>Important:</strong> If you have an upcoming order and submit your request to cancel after 5 PM on Sunday, you will be responsible for that order.</p>
      `
    },
    {
      question: 'What happens if there is an issue with my order?',
      answer: '<p>Your experience is important to us. <strong>We are proud to offer a 100% satisfaction guarantee and if you are unhappy with a particular item</strong>, please notify us via phone or email within 5 days of receiving your order and we will be happy to arrange a credit or replacement item.</p>' +
        '<p>If we run into issues or delays delivering your box, we\'ll be in contact with you as soon as we can. Due to high demand at this time, you may experience delivery delays so <strong>please be patient with us and check the email associated with your account before reaching out about a delayed delivery!</strong></p>'
    },
    {
      question: 'What should I do if my order is late or missing?',
      answer: '<p>We do our best to deliver on the promised delivery date, but there may be weeks when your order could be delayed. We currently deliver between 8:00 AM - 9:00 PM. If you have not received your delivery within that time, please reach out to us <a href="mailto:info@farmboxdl.com">info@farmboxdl.com</a> so we can look into it. </p>'
    },
    {
      question: 'What should I do with my packaging once my order arrives?',
      answer: '<p>We’re happy to recycle or reuse all of your packaging—just leave your boxes, thermal sleeves and ice packs by your door when you’re expecting a delivery and we’ll take them back.</p>'
    },
    {
      count: 6,
      question: 'What time should I expect my delivery?',
      answer: '<p>Your delivery window is any time between 8:00 AM - 9:00 PM. We cannot guarantee delivery times.</p>'
    },
    {
      question: 'Do I have to be home to accept my order?',
      answer: '<p>Nope! we’ll gladly leave your groceries in a secure spot at your home or office, like inside an unlocked front gate or with the receptionist. All cold items are packed with reusable thermal packs so they\'ll stay cold while you’re away.</p>' +
        '<p>We highly recommend you place an ice chest with ice packs out on hot summer days/nights. This will help keep all your produce and grocery items fresh. Don’t have one? We’ve got you covered! Check out the insulated cooler bag we have for sale in the "Groceries" section.</p>'
    },
    {
      question: 'How many people does a produce box serve?',
      answer: '<p>The Large Boxes should serve about 4 people per week, the Regular Boxes should serve 2-3 people per week, and the Wee Boxes about 1-2 people per week (depending on diet, lifestyle and cooking habits).</p>'
    },
    {
      question: 'How do you deliver such fresh food?',
      answer: '<p>We work directly with our local Texas farmers and foodmakers, which allows us to get their food to us (and then to you) in half the time of a traditional grocery stores or other delivery companies. That means better, more delicious food for you.</p>'
    },
    {
      question: 'Where does your produce come from?',
      answer: '<p>FarmBox Delivery believes in building local food systems. Encouraging our community to enjoy local food increases our food security as a nation and adds to the prosperity of our local economy. Local food also decreases the amount of greenhouse gases used to transport your food to your dinner plate.</p>' +
        '<p>FarmBox Delivery goal is to make local foods more accessible and convenient. Why should we not enjoy local food when it is quite simply the best food available? Remember there are working families behind every local food business including FarmBox Delivery. FarmBox Delivery is independently and locally owned. </p>'
    },
    {
      question: 'Why we carry some non-local food?',
      answer: '<p>Local food is at the heart of everything we do, yet we also know that some household staples simply aren’t available from local sources. A home cook in Dallas will likely need lemons or olive oil, just as a cook in California may regularly use pecans. And everyone needs coffee.</p>' +
        '<p>The Funny Thing About Being 100% LOCAL We’ve realized that we’re most effective at helping our local producers grow their businesses if we can replace an entire trip to the grocery store for customers. Ironically, being too rigid about local sourcing actually hurts our local producers’ sales since customers have to go elsewhere for some items. So in order to grow local food systems, we supplement our local selection with some carefully-chosen products from a wider radius.</p>'
    },
    {
      question: 'Is your produce organic?',
      answer: '<p>This is not a ‘yes’ or ‘no’ answer. Most of us are familiar with the difference between an organic product and a conventional or non-organic one, but the reality is there are more options than this: <strong>USDA Organic, IPM (Integrated Pest Management) and Beyond Organic</strong>. Most of our farmers are small scale producers and cannot afford the costs associated with becoming USDA Certified Organic; however, they often practice beyond organic processes. We source local first, whenever possible, this includes Beyond Organic and IPM options. When out-of-state selections are sourced, they include USDA Organic and IPM options.</p>' +
        '<h4>Let’s define these options:</h4>' +
        '<p><strong>USDA Organic: </strong>For foods to be certified as organic by the U.S. Department of Agriculture, they must be grown and processed according to strict guidelines that address, among many factors, soil quality, animal raising practices, pest and weed control, and use of additives. As a result of these regulations, USDA Certified Organic food is produced the way nature intended.</p>' +
        '<p>Organic produce must be grown on soil that had no prohibited substances, like synthetic fertilizers and pesticides, applied for three years prior to harvest. For organic meat, regulations require that animals are raised in living conditions accommodating their natural behaviors (like the ability to graze on pasture), fed 100% organic feed and forage, and not administered antibiotics or hormones. When it comes to processed, multi-ingredient foods, regulations prohibit artificial preservatives, colors, or flavors. For more info visit: <a href="https://www.usda.gov/topics/organic" target="_blank">https://www.usda.gov/topics/organic</a></p>' +
        '<p><strong>IPM (Integrated Pest Management): </strong>Integrated Pest Management (IPM) is an effective and environmentally sensitive approach to pest management that relies on a combination of common-sense practices. IPM programs use natural biological methods like trapping, weeding, and pheromones to discourage pests from harming their crops. If these methods fail, or there is a particularly bad outbreak, a carefully selected <a href="https://www.omri.org/about-omri-listed-products" target="_blank">OMRI approved treatment</a>, which are approved for use in organic agriculture would be used in their management program - one that causes the least possible hazard to people, property, and the environment. </p>' +
        '<p><strong>Beyond Organic:</strong> There are a few reasons why a farm might be labeled "Beyond Organic" versus ”Certified USDA Organic.” One very common reason is money. Many small farms cannot afford the thousands of dollars per year it costs to acquire and maintain certified status, even though they practice--or even exceed--the guidelines set by the USDA. Another reason is that the alternative, often regenerative, practices of these small farms have yet to be defined and regulated by the organic industry. Regenerative practices go a step further than organic standards as they strive to reverse climate change by rebuilding soil organic matter and restoring degraded soil biodiversity – resulting in both carbon drawdown and improvements to the water cycle.</p>'

    },
    {
      question: 'Do you know your food icons?',
      answer: '<p><strong>Know Your Food Icons</strong></p>' +
        '<p>We take our promise of providing transparency very seriously. The key to eating healthy is knowing how your food is grown.</p>' +
        '<p><strong>Local</strong><br>There isn’t just one definition of “local.” Some argue that food can be considered local if grown within 100 miles of where it is being sold, while the USDA defines local as within 400 miles! Because we are a major metropolitan area, we choose to define local as food grown or made within 300 miles of North Texas. </p>' +
        '<p>Eating local is one of the best ways to reduce your carbon footprint because fewer food-miles mean fewer emissions. Food grown locally also benefits your gut biome by enriching it with the biodiversity reflected in the local soil, further supporting your overall health and immune system. Lastly, buying food produced close to home is a great way to support your friends and neighbors through the local economy!</p>' +
        '<p><strong>IPM</strong><br>Integrated Pest Management (IPM) is an effective and environmentally sensitive approach to pest management that relies on a combination of common-sense practices. IPM programs use natural biological methods like trapping, weeding, and pheromones to discourage pests from harming their crops. If these methods fail, or there is a particularly bad outbreak, a carefully selected <a href="https://www.omri.org/about-omri-listed-products" target="_blank">OMRI approved treatment</a>, which are approved for use in organic agriculture would be used in their management program - one that causes the least possible hazard to people, property, and the environment. </p>' +
        '<p><stong>USDA Organic </stong><br>For foods to be certified as organic by the U.S. Department of Agriculture, they must be grown and processed according to strict guidelines that address, among many factors, soil quality, animal raising practices, pest and weed control, and use of additives. As a result of these regulations, USDA Certified Organic food is produced the way nature intended.</p>' +
        '<p>Organic produce must be grown on soil that had no prohibited substances, like synthetic fertilizers and pesticides, applied for three years prior to harvest. For organic meat, regulations require that animals are raised in living conditions accommodating their natural behaviors (like the ability to graze on pasture), fed 100% organic feed and forage, and not administered antibiotics or hormones. When it comes to processed, multi-ingredient foods, regulations prohibit artificial preservatives, colors, or flavors. For more info visit: <a href="https://www.usda.gov/topics/organic" target="_blank">https://www.usda.gov/topics/organic</a></p>' +
        '<p><strong>Beyond Organic</strong><br>There are a few reasons why a farm might be labeled "Beyond Organic" versus ”Certified USDA Organic.” One very common reason is money. Many small farms cannot afford the thousands of dollars per year it costs to acquire and maintain certified status, even though they practice--or even exceed--the guidelines set by the USDA. Another reason is that the alternative, often regenerative, practices of these small farms have yet to be defined and regulated by the organic industry. Regenerative practices go a step further than organic standards as they strive to reverse climate change by rebuilding soil organic matter and restoring degraded soil biodiversity – resulting in both carbon drawdown and improvements to the water cycle.</p>' +
        '<p><strong>Grass-fed</strong><br>Meat and dairy can be considered grass-fed when the animal eats only grasses and (and other naturally foraged plants, like hay) for the length of its life. Grass-fed meats often have a distinctly “meatier” or “gamier” taste and texture than their grain-fed counterparts. They also tend to be leaner because the animals do not consume as much starchy, fattening foods, like corn. These differences can be observed in grass-fed dairy as well, whose texture is often creamier and flavor naturally infused with subtle hints of the season’s native grasses. Most importantly, grass-fed products are more healthful because they’ve absorbed all the vitamins and minerals from the nutritionally-diverse pasture grasses.</p>' +
        '<p><strong>Grain-finished</strong><br>Meats labeled as grain-finished reflect an animal that was raised most of its life on grasses, but ate a mixture of grains and other plants once mature, often to enhance marbling (i.e. fat content). Sometimes, when selecting for certain characteristics in craft meat or other animal products, like ratios of lean meat to fat or particular flavor profiles, farmers will opt for a grain-supplemented diet. This does not mean the animals were confined in feedlots, force-fed, or fed grain alone. We do not stand for that! Our standards for grain-finished meats require that the animal enjoyed a grass-fed diet and/or pasture-raised lifestyle and were simply finished with a specific grain diet at the end of their lives</p>' +
        '<p>Another reason you might see grain-finished as a label is that, particularly due to climate change, the weather and seasons start and end dates can be unpredictable when planning the animals\' diet. Therefore, many farmers rely on supplementing their animals’ diet with grain to ensure they are well fed year-round.. Either way, FarmBox Delivery only sources the most humanely-raised, tasty craft meat possible--whether it is grass-fed or grain-finished.  </p>' +
        '<p><strong>Wild-Caught</strong><br>The fish bearing this label were caught in their natural wild environments with specific care not to destroy their habitats or overfish the population. This leads to more diversified flavors and colors as their wild diets vary with season and availability. Wild-caught fish usually cost a bit more than their farmed cousins due to the higher labor costs of sustainable fishing practices.</p>' +
        '<p><strong>Pasture-raised</strong><br>As the name states, pasture-raised animals roam in open space when and where they want within their pasture. They graze freely, consuming almost exclusively grass and other wild forages available in the field. This label goes beyond "cage-free" or even "free-range." Not only have these animals never seen a cage, they are also not beholden to anyone\'s feeding or exercise schedule but their own. They are usually provided with an open shelter they can use at will. This results in happier, healthier animals that produce tastier, more nutrient-dense products.</p>' +
        '<p><strong>Vegan</strong><br>Vegan foods do not contain anything derived from animals (e.g. dairy, eggs, gelatin, meat broths, etc.). Some producers label products vegan even though they contain honey. While we support the ethical production of honey--as it benefits the world’s bee population and supports their important role as natural pollinators--none of FarmBox Delivery products with a vegan label contain honey. </p>' +
        '<p><strong>Small-Batch</strong><br>Products displaying this label are made in small batches overseen by expert artisan producers, as opposed to mass-produced in a factory setting. The result of this type of production is homemade quality food, which often presents variations from one batch to another. Makers use their senses to adjust their recipes to compensate for naturally occurring variations in ingredients (for example: naturally saltier butter, spicier than last season’s peppers, or higher sugar content fruit). This is what puts the “art” in artisan! Additionally, food produced in small-batches generates less food waste. </p>' +
        '<p><strong>Local Ingredients</strong><br>The product bearing this label was made with at least 75% ingredients grown within 300 miles of North Texas.</p>'
    },
    {
      question: 'How is this different from a CSA?',
      answer: '<p>FarmBox Delivery offers more variety, flexibility and convenience than a typical Community Supported Agriculture (CSA) program. Because we source from many different farms, we can offer a broader variety of produce throughout the growing season. Unlike a CSA, you do not have to pay for the season up front, and you can skip a delivery or alter your schedule as needed. You only pay for the deliveries you receive. You can also customize your local produce box and let us know which fruits and veggies best satisfy your needs. Best of all, we come to you!</p>'
    },
    {
      question: 'Can I see what is going to be in my produce box weekly?',
      answer: '<p>Yes, on the website you can see what components are going to be in your produce box for the coming week. Please note that the content list of the box is not 100% due to harvest complications that may arise and cause a last minute change in the contents.</p>'
    },
    {
      question: 'Why am I seeing a processing fee?',
      answer: '<p>Because of a surprising step rise in the cost of gas, produce and pantry items, we have tentatively implemented a 6% handling fee on all orders. The change is effective immediately, but we hope it will be temporary.' +
        '<p>Rest assured that we’re working closely with our farmers and suppliers. In fact, the problems with our supply chain have only made us more aware of the ways in which we are all connected.</p>' +
        '<p>Weathering this storm together—in our case, doing our best to support local farmers and suppliers while continuing to provide high quality, affordable food to our customers—remains our key focus.</p>'
    },
    {
      question: 'What are the standards for your meat?',
      answer: '<p>FarmBox rigorously quality tests every meat supplier who hand-selects every cut. We only believe in pasture-raising animals and humane processing practices. We support ranchers who treat their animals with kindness and respect and feed their animals using the same food standards to which we hold our own food. </p>'
    },
    {
      question: 'Do you offer free delivery?',
      answer: '<p>Yes! All order subtotals $30.00 or more qualify for free delivery. Orders between our delivery minimum of $19.99 - $29.99 will include a $7.00 delivery fee. </p>'
    },
    {
      question: 'Do you offer a referral reward?',
      answer: '<p>Yes! For all of us here at FarmBox, there is no greater compliment than your referral. We love it so much that you get a $20 credit every time you refer a friend! Your friend will get a $10 credit (which cannot be combined with another offer). </p>'
    },
  ];

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  sanitizeHtml(html: string): SafeHtml {
    // const parser = new DOMParser();
    // const doc = parser.parseFromString(html, 'text/html');
    // return this.sanitizer.bypassSecurityTrustHtml(doc.body.innerHTML);

    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
