import { Component, Inject, PLATFORM_ID, TemplateRef, inject } from '@angular/core';
import { NgTemplateOutlet, isPlatformBrowser } from '@angular/common';
import { NgbToastModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from './notification.service';

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [NgbTooltipModule, NgbToastModule, NgTemplateOutlet],
  styleUrl: './notification.component.scss',
  template: `
		@if (showToasts) {
      @if( toastService.toasts()?.length ){
      <div class="custom-toast-container position-fixed p-2 animated bounceInLeft">
        @for (toast of toastService.toasts(); track toast) {
          <ngb-toast
            class="fade d-flex"
            [class]="toast.type"
            [autohide]="true"
            [delay]="toast.delay || 5000"
            (hidden)="toastService.remove(toast)"
          >
            <div class="d-flex w-100 justify-content-between gap-2">
              <div class="d-flex align-items-center">
                <span class="material-symbols-outlined btn-close-color">
                  {{getIcon(toast.type)}}
                </span>
                <div class="toast-body">
                  <!-- <i class="fas me-2"
                    [class.fa-xmark]="toast.type === 'error'"
                    [class.fa-exclamation-triangle]="toast.type === 'warning'"
                    [class.fa-circle-check]="toast.type === 'success'">
                  </i> -->
                  {{ toast.text }}
                </div>
              </div>
              <span class="material-symbols-outlined me-2 m-auto btn-close-color" (click)="toastService.remove(toast)">
                close
              </span>
            </div>
          </ngb-toast>
        }

      </div>
      }
    }
	`,
})
export class NotificationsComponent {
  showToasts = isPlatformBrowser(this.platformId);
  toastService = inject(NotificationService);
  show = false;

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  ngOnDestroy(): void {
    this.toastService.clear();
  }

  getIcon(type: string) {
    switch (type) {
      case 'success':
        return 'check_circle';
      case 'error':
        return 'error';
      case 'warning':
        return 'warning';
      case 'info':
        return 'info';
      default:
        return '';
    }
  }
}
