import { Injectable, WritableSignal, inject, signal, } from '@angular/core';
import { FilterTypes } from './search-bar/search-bar.types';
import { ProductsService } from '../../product/products.service';
import { SignalsStoreService } from '../signals-store.service';
import { RequestHandlerParams } from '../types/api-service.types';
import { ApiService } from '../api.service';
import { filter, tap } from 'rxjs';
import { Router } from '@angular/router';
import { FilterParams } from '../../product/product.types';
import { MenuService } from '../menu.service';
import { formatStringForURL } from '../common/utils';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private productService = inject(ProductsService);
  private signalsStoreService = inject(SignalsStoreService);
  private apiService = inject(ApiService);
  private router = inject(Router);
  #menuService = inject(MenuService);

  searchBarContent: WritableSignal<string> = signal('');
  displaySearchBar: WritableSignal<boolean> = signal(false);
  triggerParam: WritableSignal<boolean> = signal(false);

  clearFilters(hideSearchBar: boolean = true, validarIsInShopComponent: boolean = true) {
    this.displaySearchBar.set(!hideSearchBar);
    this.signalsStoreService.filterByProducer.set(null);

    // If the filters are currently cleaned, then, return.
    if (!this.signalsStoreService.isFiltering()) return;

    this.searchBarContent.set('');
    this.setFiltering(false);
    this.signalsStoreService.setFilterChipRemoved('');
    this.signalsStoreService.setFilters(new Map());
    // If the user is in the shop component, then, get the products.
    if (this.signalsStoreService.getIsInShopComponent() && validarIsInShopComponent) {
      // If there is not selected category, then, select the first one and navigate to that category in shop.
      let formattedCategoryName = '';
      if (this.signalsStoreService.selectedCategory().id === -1) {
        const firstCategory = this.#menuService.menu()[0];
        formattedCategoryName = formatStringForURL(firstCategory.name);
      } else
        formattedCategoryName = formatStringForURL(this.signalsStoreService.selectedCategory().name);

      if (this.router.url.includes(`/shop/${formattedCategoryName}`))
        this.productService.getProducts(this.signalsStoreService.selectedCategory().id);
      else
        this.router.navigate([`/shop/${formattedCategoryName}`]);
    }
  }

  getFrequencies() {
    const params: RequestHandlerParams = {
      method: 'GET',
      endpoint: '/subscriptions/frequencies',
      apiV3: true,
    };

    this.apiService
      .handleRequest(params)
      .pipe(
        filter((response: any) => response?.length),
        tap((response: any) =>
          this.signalsStoreService.frequencies.set(response)
        )
      )
      .subscribe();
  }

  setFiltering(fitlering: boolean) {
    this.signalsStoreService.isFiltering.set(fitlering);
  }

  updateSearchBarDisplay() {
    this.displaySearchBar.update((current: boolean) => !current);
  }

  producerRedirection() {
    if (this.signalsStoreService.filterByProducer()) {
      this.updateSearchBarDisplay();
      this.signalsStoreService.isFiltering.set(true);
      const filterParams: FilterParams = {
        type: FilterTypes.producers,
        values: [this.signalsStoreService.filterByProducer()],
      };
      this.productService.getProductsByFilter([filterParams]);
    }
  }
}
