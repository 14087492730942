import { Component, computed, inject, Signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Tag } from '../../product/product.types';
import { ProductsService } from '../../product/products.service';
import { handleImageError } from '../../shared/common/utils';

@Component({
  selector: 'app-filtering-tags',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './filtering-tags.component.html',
  styleUrl: './filtering-tags.component.scss'
})
export class FilteringTagsComponent {

  #productsService = inject(ProductsService);

  filteringTags: Signal<Tag[]> = computed(() => this.#productsService.filteringTags());

  handleImageError = handleImageError;

  removeFromFilter(tag: Tag) {
    tag.isChecked.set(false);
    this.#productsService.filterBySelectedTags();
  }
}
