<section>

  <h2 class="h2-semibold text-center">
    Your preferences
  </h2>

  <p class="h5-regular">
    At FarmBox, we tailor your shopping experience to <strong>YOU</strong>. When you tell us about your dislikes and
    likes we make sure
    to take that into consideration every week when we prepare your box. Please tell us a few of your personal
    preferences so that we can get to know you better.
  </p>

  <p class="h5-regular">
    <b>💡 PRO TIP:</b>
    You can easily add more likes and dislikes later as you shop the store by clicking the heart or the thumbs down
    icons.
  </p>

  <section class="row">

    @defer (on immediate; when subscriptionCard().length) {
    @if (subscriptionCard()) {
    @for (product of subscriptionCard(); track product.id) {

    @if (isMobile()) {
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 animated fadeIn">
      <div class="product-card-grid__item">
        <app-mobile-product-card [isPreference]="true" [isSignupFlow]="true" [card]="{product}"
          (outClickFavoriteOrDislike)="clickFavOrDisliked($event)"></app-mobile-product-card>
      </div>
    </div>
    } @else {
    <div class="col-xxl-3 col-lg-4 col-12 animated fadeIn">
      <div class="product-card-grid__item">
        <app-product-card [isPreference]="true" [isSignupFlow]="true" [card]="{product}"
          (outClickFavoriteOrDislike)="clickFavOrDisliked($event)"></app-product-card>
      </div>
    </div>
    }
    }
    }
    }

  </section>

  <div class="d-grid gap-2 mt-4 col-md-4 col-12 mx-auto">
    <button type="button" class="btn btn-secondary" [disabled]="!canContinue()" (click)="continue()">
      Continue
    </button>
  </div>

</section>