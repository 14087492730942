import { Component, Inject, OnInit, PLATFORM_ID, Signal, computed, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ProducersService } from '../producers.service';
import { CarouselComponent } from '../../../shared/carousel/carousel.component';
import { handleImageError } from '../../../shared/common/utils';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-producer-detail',
  standalone: true,
  imports: [CommonModule, CarouselComponent],
  templateUrl: './producer-detail.component.html',
  styleUrl: './producer-detail.component.scss',
})
export class ProducerDetailComponent implements OnInit {
  private activatedRoute = inject(ActivatedRoute);
  private producersService = inject(ProducersService);
  private sanitizer = inject(DomSanitizer);
  #router = inject(Router);

  foodItem: any[] = []
  seoURL!: string;
  producerDetails: Signal<any> = computed(
    () => this.producersService.producerDetailsSignal() || null
  );
  loadingProducts: Signal<any> = computed(
    () => !!this.producersService.isLoadingProducts()
  );
  carouselProducerProducts: Signal<any | undefined> = computed(() =>
    this.setUpCarouselItems()
  );

  handleImageError = handleImageError;

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  ngOnInit(): void {
    this.handleRouteChanges();
  }

  private handleRouteChanges(): any {
    this.activatedRoute.params.subscribe((params) => {
      this.seoURL = `${params['producerUrl']}`;
      this.producersService.getProducerByUrl(this.seoURL);
    });
  }

  private setUpCarouselItems() {
    const items = this.producerDetails()?.products.map((product: any) => {
      product.image = product.img;
      product.name = product.name;
      if (typeof product?.price === 'number')
        product.price = product.price.toFixed(2);

      return {
        content: {
          product: product,
          settings: { isCardInOrderPage: true },
        },
      };
    });
    return items;
  }

  sanitizeHtml(html: string): SafeHtml {
    if (!isPlatformBrowser(this.platformId) || !DOMParser) return '';
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return this.sanitizer.bypassSecurityTrustHtml(doc.body.innerHTML);
  }

  back() {
    this.#router.navigate(['/pages/producers']);
  }

  goToHome() {
    this.#router.navigate(['/home']);
  }
}
