@if (isContentLoading()) {
<mat-progress-bar class=" rounded layout-shop__progress-bar" mode="indeterminate" value="accent"></mat-progress-bar>
}
<div class="layout-shop" [class.layout--sidebar-closed]="false">
  <!-- Sidebar -->
  @if(isContentLoaded() && !isMobile()){
  <div class="layout-shop__sidebar" [class.sidebar--closed]="false">
    <app-sidebar></app-sidebar>
  </div>
  }

  <section class="w-100">

    <!-- Bundle view -->
    @if (!isMobile()) {
    @for(bundle of bundles(); track bundle) {
    <section class="layout-shop__bundle">

      <div class="d-flex flex-column">
        <h5 class="h5-bold m-0">
          {{bundle.name}}
        </h5>
        <span class="h6-regular">
          {{bundle.frequency}}
        </span>
      </div>

      <button type="button" class="btn btn-outline-secondary" (click)="goToCustomBox(bundle)">
        Customize box
      </button>

    </section>
    }
    }

    @if (filteringTags().length) {
    <div class="filtering-tags-container">
      <app-filtering-tags></app-filtering-tags>
    </div>
    }

    <!-- Main content -->
    <div class="layout-shop__main" [class.height-layout-shop__main]="!hasGlobalMessages()"
      [class.height-layout-shop__main-global-message]="hasGlobalMessages()"
      class="{{bundles().length && !isMobile() ? 'height-main-'+bundles().length+'-bundle': 'height-main-whitout-bundle'}}{{hasGlobalMessages() ? '-global-message' : ''}}"
      (scroll)="onScroll($event)" #layoutShopMain id="layoutShopMain">

      @if(!marketStatus().isOpen) {
      <div class="closed-market-container animated fadeIn">
        <div class="alert" role="alert">
          <span class="material-symbols-outlined mat-icon-fill icon">
            calendar_today
          </span>
          <div class="text-container">

            <p class="header">{{closedMarket().title}}</p>
            <p class="legend">{{closedMarket().legend}}</p>
          </div>
        </div>
      </div>
      }

      <div class="container-fluid">

        @if (filters().size) {
        <ul class="filter-chips-container">
          @for (filter of filters().entries(); track $index) {
          <li>
            <mat-chip-row (removed)="removeChipFilter(filter[0])" [editable]="true" class="bg-secondary bg-opacity-25">
              <div class="d-flex gap-1">
                <b>{{filter[1].type}}:</b> <span class="fst-italic text-decoration-underline">{{filter[1].value}}</span>
              </div>
              <button matChipRemove [attr.aria-label]="'remove ' + filter[0]">
                <span class="material-symbols-outlined">
                  close
                </span>
              </button>
            </mat-chip-row>
          </li>
          }
        </ul>
        }

        @if (!isContentLoading() && isContentLoaded()) {
        @if (((hasSession() && (!stockService.mapStockSignal().size || !productsSignal().length))) ||
        (!hasSession() &&
        !productsSignal().length)) {
        <app-empty-message [message]="emptyMessage" class="mt-4"></app-empty-message>
        }
        }

        <div class="row product-card-grid" id="scroll-to-top-ref">
          @defer (on immediate; when productsSignal().length) {
          @if (productsSignal()) {

          @for (product of productsSignal(); track product.id; let index = $index) {

          <!-- CATEGORY -->
          @if (product.categoryInView && product.categoryInView.show) {
          @if (product.categoryInView.banner) {
          <a [href]="product.categoryInView.url ? product.categoryInView.url : 'javascrip:void(0)'">
            <img [src]="product.categoryInView.banner" alt="Banner of category: {{product.category.name}}"
              class="img-fluid animated fadeInDown w-100 mb-4">
          </a>
          } @else if(displayCategoryName()) {
          <h3 class="mb-2 mb-sm-4 fw-bold category" [attr.category-id]="product.category.id" [id]="product.category.id">
            {{ product.category.name }}
          </h3>
          }
          }

          <!-- SUB-CATEGORY -->
          @if (product.subCategoryInView && product.subCategoryInView.show) {
          @if (product.subCategoryInView.banner) {
          <a [href]="product.subCategoryInView.url ? product.subCategoryInView.url : 'javascrip:void(0)'">
            <img [src]="product.subCategoryInView.banner" alt="Banner of subcategory: {{product.subcategory.name}}"
              class="img-fluid animated fadeInDown w-100 mb-4">
          </a>
          } @else if(displayCategoryName()) {
          <h3 class="mb-2 mb-sm-4 category" [id]="product.category.id + '-' + product.subcategory.id"
            [attr.category-id]="product.category.id" [attr.sub-category-id]="product.subcategory.id" #subcategory>
            {{ product.subcategory.name }}
          </h3>
          }
          }

          <!-- SUB-CATEGORY-SON -->
          @if (product.subCategorySonInView && product.subCategorySonInView.show) {
          @if (product.subCategorySonInView.banner) {
          <a [href]="product.subCategorySonInView.url ? product.subCategorySonInView.url : 'javascrip:void(0)'">
            <img [src]="product.subCategorySonInView.banner"
              alt="Banner of subcategoryson: {{product.subcategorySon?.name}}"
              class="img-fluid animated fadeInDown w-100 mb-4">
          </a>
          } @else if(displayCategoryName()) {
          <h3 class="mb-2 mb-sm-4 category"
            [id]="product.category.id + '-' + product.subcategory.id + '-' + product.subcategorySon?.id"
            [attr.category-id]="product.category.id" [attr.sub-category-id]="product.subcategory.id"
            [attr.sub-category-son-id]="product.subcategorySon?.id" #subcategorySon>
            {{ product.subcategorySon?.name }}
          </h3>
          }
          }

          @defer (on viewport; prefetch on immediate) {
          @if (isMobile()) {
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 animated fadeIn" id="{{product.id}}">
            <div class="product-card-grid__item">
              <app-mobile-product-card [card]="{product}"></app-mobile-product-card>
            </div>
          </div>
          }@else {
          <div class="col-xxl-3 col-lg-4 col-6 animated fadeIn" id="{{product.id}}">
            <div class="product-card-grid__item">
              <app-product-card [card]="{ product }">
              </app-product-card>
            </div>
          </div>
          }
          } @placeholder(minimum 500ms) {
          <div [ngClass]="{'col-xxl-3 col-lg-4 col-6': !isMobile(), 'col-xs-12 col-lg-6 col-sm-6': isMobile()}">
            @if (isMobile()) {
            <div class="animated fadeInUp d-flex gap-3 mb-3 placeholder-glow skeleton-product-mobile">

              <div class="placeholder rounded-2 img bg-secondary"></div>

              <div class="placeholder-glow content d-flex flex-column justify-content-center gap-3">

                <div class="d-flex gap-2 flex-row-reverse">
                  <span class="col-3 rounded-5 placeholder bg-secondary"></span>
                  <span class="col-3 rounded-5 placeholder bg-secondary"></span>
                </div>

                <div class="d-flex flex-column gap-2">
                  <span class="col-12 rounded-2 placeholder bg-secondary"></span>
                  <span class="col-8 rounded-2 placeholder bg-secondary"></span>
                  <span class="col-4 rounded-2 placeholder bg-secondary"></span>
                </div>

                <div class="d-flex gap-2 mb-3">
                  <span class="col-2 rounded-5 placeholder bg-secondary"></span>
                  <span class="col-2 rounded-5 placeholder bg-secondary"></span>
                  <span class="col-2 rounded-5 placeholder bg-secondary"></span>
                </div>

              </div>
            </div>
            } @else {
            <div class="animated fadeInUp fadeIn mb-3" id="{{product.id}}">
              <div class="card position-relative border p-3 row-gap-3" aria-hidden="true">
                <span class="placeholder bg-secondary col-12"></span>
                <span class="placeholder bg-secondary col-6"></span>
                <span class="placeholder bg-secondary col-6"></span>
                <div class="placeholder bg-secondary col-12" style="height: 100px;"></div>
                <div class="card-body">
                  <h5 class="card-title placeholder-glow">
                    <span class="placeholder bg-secondary col-6"></span>
                  </h5>
                  <p class="card-text placeholder-glow">
                    <span class="placeholder bg-secondary col-7"></span>
                    <span class="placeholder bg-secondary col-4"></span>
                    <span class="placeholder bg-secondary col-4"></span>
                    <span class="placeholder bg-secondary col-6"></span>
                    <span class="placeholder bg-secondary col-8"></span>
                  </p>
                  <a class="btn btn-secondary disabled placeholder col-12" aria-disabled="true"></a>
                </div>
              </div>
            </div>
            }
          </div>
          }
          }
          }
          }
          @placeholder { LOADING... }
        </div>
      </div>

      @if (productsSignal().length) {
      @defer(on timer(500ms)) {
      <app-footer></app-footer>
      }
      }
    </div>
  </section>
</div>