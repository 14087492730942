import { Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, Renderer2, WritableSignal, computed, inject, signal } from '@angular/core';
import { CommonModule, isPlatformBrowser } from "@angular/common";
import { FooterComponent } from "../shared/footer/footer.component";
import { Router, RouterLink } from "@angular/router";
import { ResolutionService } from '../shared/resolution.service';
import { SignalsStoreService } from '../shared/signals-store.service';
import { HttpClient } from '@angular/common/http';
import { InstagramService } from '../shared/instagram.service';
import { InstagramPost } from '../shared/types/common.types';
import { InstagramMediaType } from '../shared/types/common.enums';
import KeenSlider from 'keen-slider';
import { tap } from 'rxjs';
import { LOCALSTORAGE_KEYS } from '../shared/constants/databases';
import { LocalStorageService } from '../shared/local-storage.service';
import { MenuService } from '../shared/menu.service';
import { formatStringForURL } from '../shared/common/utils';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    FooterComponent,
    RouterLink
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  host: { 'class': 'home-page' }
})
export class HomeComponent implements OnInit {
  blogUrl: string = "https://blog.farmboxdelivery.com";
  posts: any[] = [];
  private resolutionService = inject(ResolutionService);
  private signalsStoreService = inject(SignalsStoreService);
  #router = inject(Router);
  #menuService = inject(MenuService);
  #instagramService = inject(InstagramService);
  #localStorageService = inject(LocalStorageService);

  hasSession = computed(() => this.signalsStoreService.hasSession());

  featureProducts = [
    {
      img: 'assets/images/landing/home/feature-products/1.webp',
      title: 'Farm Boxes',
      category: 'FarmBoxes'
    },
    {
      img: 'assets/images/landing/home/feature-products/2.webp',
      title: 'Dairy',
      category: 'Dairy & Eggs'
    },
    {
      img: 'assets/images/landing/home/feature-products/3.webp',
      title: 'Produce',
      category: 'Produce'
    },
    {
      img: 'assets/images/landing/home/feature-products/4.webp',
      title: 'Bakery',
      category: 'Bakery'
    },
    {
      img: 'assets/images/landing/home/feature-products/5.webp',
      title: 'Drinks',
      category: 'Drinks'
    },
    {
      img: 'assets/images/landing/home/feature-products/6.webp',
      title: 'Meat & Fish',
      category: 'Meats'
    },
    {
      img: 'assets/images/landing/home/feature-products/7.webp',
      title: 'New Arrivals',
      category: 'New Arrivals'
    },
    {
      img: 'assets/images/landing/home/feature-products/8.webp',
      title: 'Fresh Picks',
      category: 'Fresh Picks'
    }
  ];
  testimonials = [
    {
      count: 1,
      quote: 'We’ve been getting our vegetables from here for the last few months and I could not be more pleased. The quality has been amazing and it really helps keep our dinners nice and healthy at home. Fantastic service!',
      name: 'Bobby E',
    },
    {
      count: 2,
      quote: 'We just received our first FarmBox delivery and couldn’t be happier. The quality of the fruits and veggies is amazing! We love knowing that we are supporting local farmers and eating nutritious foods. Five stars!!',
      name: 'Shannon Duke Fan',
    },
    {
      count: 3,
      quote: 'Lovely service! The produce is amazing, their ever growing website has so many new products, and their customer service is always pleasant and eager to help! Definitely recommend!',
      name: '•Sacred Woman•',
    }, {
      count: 4,
      quote: 'Great food, easy to customize, and so simple to change delivery when I\'m out of town! I\'ve tried others but this one is the best!',
      name: 'Dana Phillips',
    },
    {
      count: 5,
      quote: 'I love my weekly farm box delivery! I get a weekly produce box and it\'s been so good. The fruits and vegetables are fresh and delicious. I have also ordered eggs, milk, cream, and beef liver. Everything comes packaged well and they take the old boxes and packaging to be reused. Their customer service is great! They are responsive and polite. I LOVE FARM BOX DELIVERY',
      name: 'Kayla Fudge',
    },
    {
      count: 6,
      quote: 'Loving the farm box experience! Everything has been fresh and ripe and I love that it\'s delivered. I think the price is great. I\'ve paid just as much for only organic berries and now I get a week off organic produce for my family delivered. Excellent customer service, super nice. I highly recommend if you are in DFW.',
      name: 'Sara Hanshew',
    },
  ];
  testimonials2 = [
    {
      count: 1,
      quote: 'Its really nice to get farm fresh food without having to worry about crowds at farmers markets.',
      name: 'Jacqui D.',
    }, {
      count: 2,
      quote: 'A big factor for me is less waste. I like that Farmhouse is local, supports local farmers and picks up the packaging when they deliver the next week',
      name: 'Sarah C.',
    },
    {
      count: 3,
      quote: 'My Farmhouse Delivery box is the best part of my week. My palate feels expanded and horizons broadened, it’s like getting presents.',
      name: 'Margaret F.',
    },
    {
      count: 4,
      quote: 'A big factor for me is less waste. I like that Farmhouse is local, supports local farmers and picks up the packaging when they deliver the next week',
      name: 'Sarah C.',
    },
    {
      count: 5,
      quote: 'My Farmhouse Delivery box is the best part of my week. My palate feels expanded and horizons broadened, it’s like getting presents.',
      name: 'Margaret F.',
    },
  ];

  sections: HTMLElement[] = [];

  videoUrl: WritableSignal<string> = signal('');

  isMobile = computed(() => this.resolutionService.isMobile())

  instagramPost = signal<InstagramPost[]>([]);

  IGImage = InstagramMediaType.IMAGE;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    if (!isPlatformBrowser(this.platformId)) return;
    this.#getInstagramMedia();
  }

  ngOnInit() {
    // Retrieve all sections
    const sectionElements = this.elementRef.nativeElement.querySelectorAll('.has-animation');
    this.sections = Array.from(sectionElements);

    //WordPress post
    if (isPlatformBrowser(this.platformId))
      this.getLatestPosts();
  }

  // WordPress post
  getLatestPosts(): void {
    const apiUrl = this.blogUrl + '/wp-json/wp/v2/posts?per_page=3&orderby=date&order=desc';

    this.http.get<any[]>(apiUrl).subscribe(
      (data) => {
        if (data && Array.isArray(data)) {
          this.posts = data;
          this.getFeaturedImages();
          this.fetchCategoryNames();
        } else {
          console.warn('No valid data fetched');
        }
      },
      (error) => {
        console.error('Error fetching posts:', error);
      }
    );
  }
  // WordPress post img
  getFeaturedImageUrl(mediaId: number): void {
    const mediaUrl = this.blogUrl + `/wp-json/wp/v2/media/${mediaId}`;

    this.http.get<any>(mediaUrl).subscribe(
      (mediaData) => {
        // Add the image URL to the post object
        const imageUrl = mediaData.source_url;
        this.posts.forEach((post) => {
          if (post.featured_media === mediaId) {
            post.featured_image_url = imageUrl;  // Add image URL to the post
          }
        });
      },
      (error) => {
        console.error('Error fetching featured media:', error);
      }
    );
  }

  //WordPress get categories
  fetchCategoryNames(): void {
    this.posts.forEach(post => {
      if (post.categories && post.categories.length > 0) {
        const categoryIds = post.categories.join(',');
        const categoryUrl = `${this.blogUrl}/wp-json/wp/v2/categories?include=${categoryIds}`;

        this.http.get<any[]>(categoryUrl).subscribe(
          (categories) => {
            post.categoriesData = categories.map(cat => ({ id: cat.id, name: cat.name, modifiedId: cat.id % 18 }));
          },
          (error) => {
            console.error('Error fetching categories:', error);
          }
        );
      } else {
        post.categoryNames = [];
      }
    });
  }


  getFeaturedImages(): void {
    this.posts.forEach((post) => {
      if (post.featured_media) {
        this.getFeaturedImageUrl(post.featured_media);  // Fetch image for each post
      }
    });
  }


  @HostListener('scroll', ['$event'])
  onElementScroll(event: any) {
    const scrollPosition = event.target.scrollTop;
    const windowHeight = event.target.clientHeight;

    // Loop through each section
    this.sections.forEach(section => {
      const sectionOffset = section.offsetTop;
      const sectionHeight = section.offsetHeight;

      // Check if the section is in the viewport
      if (scrollPosition >= sectionOffset - windowHeight && scrollPosition < sectionOffset + sectionHeight) {
        // Add a class to the section
        this.renderer.addClass(section, 'animated');
      } else {
        // Remove the class from the section
        this.renderer.removeClass(section, 'animated');
      }
    });
  }

  referFriend() {
    if (this.signalsStoreService.hasSession())
      this.#router.navigate(['/settings/account/friend-referrals']);
    else
      this.#router.navigate(['/signup']);
  }

  async #getInstagramMedia() {

    this.instagramPost.set([]);

    // this.#instagramService
    //   .getAllMedia(12)
    //   .pipe(
    //     tap(result => {

    //       if (!result || !result.data || !result.data.length)
    //         return;

    //       this.instagramPost.set(result.data);

    //       setTimeout(() => {
    //         this.instagramPost()
    //           .filter(x => x.media_type === InstagramMediaType.CAROUSEL_ALBUM)
    //           .forEach(x => {
    //             this.#initInstagramSlider(x);
    //           });
    //       }, 1000);
    //     })
    //   )
    //   .subscribe();
  }

  #initInstagramSlider(post: InstagramPost) {

    post.currentSlider = 0;
    const sliderElement = this.elementRef.nativeElement.querySelector(`#instagram-slider-${post.id}`);

    post.slider = new KeenSlider(sliderElement, {
      initial: post.currentSlider,
      loop: true,
      slideChanged: s => post.currentSlider = s.track.details.rel
    });

    if (post.slider.track?.details?.slides?.length) {
      post.dotHelper = [
        ...Array(post.slider.track.details.slides.length)
      ];
    }
  }

  goToShop(categoryName: string) {

    const category = this.#menuService
      .menu()
      .find(x => x.name.toLowerCase() === categoryName.toLowerCase());

    if (!category)
      return;

    const formattedCategoryName = formatStringForURL(category.name);
    this.signalsStoreService.selectedCategory.set(category);
    this.#localStorageService.set(LOCALSTORAGE_KEYS.SELECTED_CATEGORY, formattedCategoryName);
    this.#router.navigate([`/shop/${formattedCategoryName}`]);
  }
}



