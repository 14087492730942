import { AfterViewInit, Component, ElementRef, Inject, NgZone, PLATFORM_ID, Signal, ViewChild, WritableSignal, computed, inject, signal } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ProfileService } from './profile.service';
import { toObservable } from '@angular/core/rxjs-interop'
import { NotificationService } from '../../../shared/notification/notification.service';
import { ModalContentService } from '../../../shared/modal-content/modal-content.service';
import { ModalContentTypes } from '../../../shared/constants/modal-content-types';
import { AddressService, NavegoAddressRequest, NavegoAddressResponse } from '../../../shared/address.service';
import { GoogleMapsUtils } from '../../../shared/common/utils';
import { DeliveryAddress } from '../../../authentication/signup/signup.types';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from '../../../../environments/environment';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AccountGetResponse, AccountPutPaylaod } from './profile.types';
import { AccountService } from '../../../shared/account.service';
import { AccountSettings, ArrayOptionsItem, CustomerType, DeliveryDay, DeliveryOptionsResponseItem, PickUpOption } from '../../../shared/types/account.types';
import { combineLatest, tap } from 'rxjs';
import { DeliveriesService } from '../deliveries/deliveries.service';
import { FormattedDeliveryInformation } from '../deliveries/intarfaces';
import { SignalsStoreService } from '../../../shared/signals-store.service';
import { MatRadioModule } from '@angular/material/radio';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { CUSTOM_DATE_FORMATS } from '../../../shared/constants/date-formtats';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { EmptyMessageComponent } from '../../../shared/empty-message/empty-message.component';
import { DeliveryInformation } from '../deliveries/intarfaces';
import { LocalStorageService } from '../../../shared/local-storage.service';
import { SettingsService } from '../../settings.service';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    CommonModule,
    MatProgressBarModule,
    MatRadioModule,
    FormsModule,
    MatDatepickerModule,
    EmptyMessageComponent
  ],
  providers: [
    ProfileService,
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: environment.apis.google.places,
        libraries: ['places'],
        region: 'US',
      }),
    },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS.MONTH_DAY_YEAR },
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements AfterViewInit {

  @ViewChild('autocompleteInput') autocompleteInput!: ElementRef<HTMLInputElement>;

  private addressService = inject(AddressService);
  private deliveriesService = inject(DeliveriesService);
  private formBuilder = inject(FormBuilder);
  private modalService = inject(ModalContentService);
  private notificationService = inject(NotificationService);
  private profileService = inject(ProfileService);
  private zone = inject(NgZone);
  private loader = inject(Loader);
  private accountService = inject(AccountService);
  private signalsStoreService = inject(SignalsStoreService);
  #localStorageService = inject(LocalStorageService);
  #settingsService = inject(SettingsService);

  isWaitingForAPIResponse = computed(() => this.#settingsService.isWaitingForAPIResponse());
  submitButtonText = computed(() => this.#setUpSubmitButtonText())

  googleAddress: any = signal(undefined);
  hasDeliveryAddressCoverage: any = signal(false);
  isGoogleAddressFilled = signal(false);
  isLoadingContent: any = computed(() => this.addressService.isLoading());
  profileData = computed(() => this.profileService.profileData());
  profileData$ = toObservable(this.profileData);
  membershipOptions = computed(() => this.profileService.membserhipOptionsData());
  membershipOptions$ = toObservable(this.profileService.membserhipOptionsData);
  profileForm!: FormGroup;
  disableSubmitButton = computed(() => this.validatSubmitButtonState())

  dietaryPreferences: WritableSignal<any[]> = signal([])
  referralSource: WritableSignal<{ id: any, name: string, hasAdditionalInfo: boolean }[]> = signal([]);

  deliveryAddress: Partial<DeliveryAddress> = {
    additionalAddress: '',
    address: '',
    city: '',
    deliveryNotes: '',
    state: '',
    zipCode: undefined,
  }

  currentDeliveryAddress: Partial<DeliveryAddress> = { ...this.deliveryAddress };

  isUpdatingAddress = signal(false);

  selectedDeliveryDay: WritableSignal<DeliveryOptionsResponseItem | null> = signal(null);
  selectedDeliveryTimeWindow: any = signal(null);
  deliveryOptions: DeliveryOptionsResponseItem[] = [];
  timeSet: Signal<ArrayOptionsItem[]> = computed(() => this.setUpTimeSet());
  isTemporaryRouteChange = signal(false);
  temporaryRouteChangeMessage = signal('');

  membershipList: WritableSignal<CustomerType[]> = signal([]);
  selectedMembership: WritableSignal<CustomerType> = signal({ id: 0, name: '' });
  currentCustomerMembershipType: WritableSignal<CustomerType> = signal({ id: 0, name: '' });
  shouldShowAddressDiscardChanges = computed(() => {
    const selected = this.selectedMembership();
    const current = this.currentCustomerMembershipType();
    return (this.selectedMembership().id == 0 ? (this.profileData() && this.profileData().settings && this.profileData().settings.requireAddress) : this.selectedMembership().configuration?.requireAddress) && selected.id === current.id;
  })

  pickupLocationList: WritableSignal<PickUpOption[]> = signal([]);
  selectedPickupLocation: WritableSignal<PickUpOption> = signal({ id: 0, name: '', deliveryDays: [], locationAddress: '' });
  pickupDeliveryDaysList: WritableSignal<DeliveryDay[]> = signal([]);
  selectedPickupDeliveryDay: WritableSignal<DeliveryDay | null> = signal(null);

  deliveryInfo: WritableSignal<FormattedDeliveryInformation | null> = this.deliveriesService.deliveryZoneInfo;

  isDisableCustomerTypeUpdate = computed(() => {
    const interchangeableMemebserhip = this.membershipOptions() || [];
    return !interchangeableMemebserhip.length;
  })

  hasPickUpLocationFlow = signal(environment.config.flows.settings.profile.hasPickupLocationsFlow);

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    this.initForm();
    this.#getDeliveryZoneInfo();
  }

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.platformId)) return;

    this.getProfileData();
    this.profileService.getMembershipOptions().subscribe();

    combineLatest([
      this.profileData$,
      this.accountService.getAllCustomerTypes(),
      this.membershipOptions$,
    ]).pipe(
      tap(([profileData, allCustomerTypes, membershipOptions]) => this.setUpInitialData(profileData, allCustomerTypes, membershipOptions))
    ).subscribe()
  }

  private setUpInitialData(profileData: AccountGetResponse, allCustomerTypes: any, membershipOptions: any) {
    if (!profileData || !membershipOptions) return;
    this.setUpProfileData(profileData);
    this.setUpCustomerTypes(allCustomerTypes.data, membershipOptions);

    const membershipId: any = this.profileData().delivery?.type;
    if (membershipId)
      this.getPickUpLocations(membershipId);
  }

  private setUpCustomerTypes(allCustomerTypes: any[], membershipOptions: any[]) {
    if (allCustomerTypes.length) {
      const allMembershipList = [...membershipOptions];

      const currentCustomerMembershipType = allCustomerTypes.find((r: any) => r.id === this.profileData().delivery?.type);
      if (currentCustomerMembershipType) {
        allMembershipList.unshift(currentCustomerMembershipType);
        this.membershipList.set(allMembershipList);
        this.selectedMembership.set(currentCustomerMembershipType);
        this.currentCustomerMembershipType.set(currentCustomerMembershipType);
      } else {
        this.membershipList.set(allMembershipList);
      }


    } else if (membershipOptions.length) {
      this.selectedMembership.set(membershipOptions[0]);
      this.currentCustomerMembershipType.set(membershipOptions[0]);
    }

    if (this.selectedMembership()?.configuration?.requireAddress)
      this.setUpGMapsAutocomplete();
  }

  private getPickUpLocations(membershipId: any) {
    this.accountService.getPickUpOption(membershipId).pipe(tap(pickUpLocations => this.setUpPickUpLocations(pickUpLocations))).subscribe()
  }

  private setUpPickUpLocations(pickUpLocations: any[]) {
    if (pickUpLocations.length) {
      const p = pickUpLocations.map(l => {
        l.composedName = `${(l.name ? `${l.name} - ` : '') || ''}${l.locationAddress}`;
        return l;
      })
      this.pickupLocationList.set(p);
      if (this.profileData().address.zoneId && !this.profileData().settings.requireAddress) {
        const selectedPickupLocation = p.find((p: any) => p.id === this.profileData().address.zoneId);
        if (selectedPickupLocation) {
          this.selectedPickupLocation.set(selectedPickupLocation);
          this.pickupDeliveryDaysList.set(this.selectedPickupLocation().deliveryDays);

          if (this.profileData().delivery.dayId) {
            const selectedPickupDeliveryDay = this.pickupDeliveryDaysList().find((pd: any) => pd.id === this.profileData().delivery.dayId);
            if (selectedPickupDeliveryDay)
              this.selectedPickupDeliveryDay.set(selectedPickupDeliveryDay);
          }
        }
      } else {
        this.selectedPickupLocation.set(p[0]);
        this.pickupDeliveryDaysList.set(this.selectedPickupLocation().deliveryDays);
      }

      // const currentCustomerDeliveryDate = this.pickupDeliveryDaysList().find(d => d.id === this.profileData().delivery.dayId);
      // if (currentCustomerDeliveryDate) {
      //   this.selectedPickupDeliveryDay.set(currentCustomerDeliveryDate);
      //   this.pickupDeliveryDaysList.set(this.selectedPickupLocation().deliveryDays);
      // }
    }
  }

  private initForm() {
    this.profileForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phone: ['', Validators.required],
      email: [{ value: '', disabled: true }, [Validators.required, Validators.email]],
      password: [{ value: '******', disabled: true }, Validators.required],
      birthday: [{ value: null }, [this.isAtLeast15YearsOld()]],
      howDidYouHear: [{ value: '', disabled: true }],
      additionalInfo: [{ value: '', disabled: true }],
      address: this.formBuilder.group({
        street: [{ value: '', disabled: true }, Validators.required],
        additional: [''],
        city: [{ value: '', disabled: true }, Validators.required],
        state: [{ value: '', disabled: true }, Validators.required],
        zipCode: [{ value: '', disabled: true }, Validators.required],
        deliveryNotes: [String()],
      }),
      dietaryInformation: this.formBuilder.group({
        hasRestriction: [false],
        selectedProducts: [[]]
      }),
      settings: this.formBuilder.group({
        receiveNewsletter: [],
        receiveText: [],
        receiveTextDelivery: [],
      }),
    }, { validators: this.customValidator.bind(this) })
  }

  private customValidator: ValidatorFn = (group: AbstractControl): { [key: string]: any } | null => {
    const howDidYouHearControl = group.get('howDidYouHear');
    const advertisementControl = group.get('additionalInfo');

    if (howDidYouHearControl && advertisementControl) {
      const howDidYouHearValue = howDidYouHearControl.value;
      const HDYHAUInfo = this.referralSource ? this.referralSource().find(r => r.id === howDidYouHearValue) : null;

      if (HDYHAUInfo?.hasAdditionalInfo) {
        advertisementControl.setValidators(Validators.required);
        if (!advertisementControl.value) {
          advertisementControl.setErrors({ required: true });
        }
      } else {
        advertisementControl.setValidators(null);
        advertisementControl.setErrors(null);
        return null;
      }
    }

    return null;
  }

  private isAtLeast15YearsOld(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;  // Si el campo está vacío, no aplicar la validación
      }

      const birthDate = new Date(control.value);
      const today = new Date();
      const minimumAge = 15;
      let age = today.getFullYear() - birthDate.getFullYear();
      const month = today.getMonth() - birthDate.getMonth();
      if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age >= minimumAge ? null : { isAtLeast15YearsOld: true };
    };
  }

  validateErrors(controlName: string): string | null {
    const control = this.profileForm.get(controlName);
    if (control?.valid || (!control?.dirty && !control?.touched)) return null;
    if (control?.hasError('required')) {
      return `This field is required`
    } else if (control?.hasError('email')) {
      return 'Invalid email format'
    } else if (control?.hasError('isAtLeast15YearsOld')) {
      return 'You must be at least 15 years old.';
    }
    return null;
  }

  private getProfileData() {
    this.profileService.get().subscribe();
    this.profileData$.subscribe((data: AccountGetResponse) => data ? this.setUpProfileData(data) : null)
  }

  private setUpProfileData(data: AccountGetResponse) {
    this.profileForm.setValue(this.setUpFormValues(data));
    this.formatPhoneNumber();
    this.getAccountSettings();
  }

  private setUpFormValues(data: AccountGetResponse) {
    this.currentDeliveryAddress = {
      "address": data.address?.street || '',
      "additionalAddress": data.address?.additional || '',
      "city": data.address?.city || '',
      "state": data.address?.state || '',
      "zipCode": data.address?.zip || '',
      "deliveryNotes": data.accountInfo?.noteDriver || ''
    }

    return {
      "firstName": data.accountInfo?.firstName || '',
      "lastName": data.accountInfo?.lastName || '',
      "phone": data.accountInfo?.phone || '',
      "email": data.accountInfo?.email || '',
      "password": '****',
      "birthday": data.accountInfo?.birthday || '',
      "howDidYouHear": data.settings?.referral?.source || '',
      "additionalInfo": data.settings?.referral?.additionalInfo || '',
      "address": {
        "street": data.address?.street || '',
        "additional": data.address?.additional || '',
        "city": data.address?.city || '',
        "state": data.address?.state || '',
        "zipCode": data.address?.zip || '',
        "deliveryNotes": data.accountInfo?.noteDriver || ''
      },
      "dietaryInformation": {
        "hasRestriction": !!(data.accountInfo?.dietaryPreferences?.length),
        "selectedProducts": data.accountInfo?.dietaryPreferences || []
      },
      "settings": {
        "receiveNewsletter": data.settings.receiveNewsletter || false,
        "receiveText": data.settings.receiveText || false,
        "receiveTextDelivery": data.settings.receiveTextDelivery || false,
      }
    }
  }

  showAdvertisementField() {
    const selected = this.profileForm.get('howDidYouHear')?.value;
    const info = this.referralSource().find(r => r.id === selected);
    return info?.hasAdditionalInfo || false;
  }

  showDietaryRestrictions() {
    return this.profileForm.get('dietaryInformation.hasRestriction')?.value
  }

  saveChanges(event: Event) {
    event.preventDefault();
    if (!this.profileForm.valid) {
      this.profileForm.markAllAsTouched();
      return this.notificationService.show({ text: 'Validate mandatory fields', type: 'error' });
    }
    if (!this.isValidAddressUpdate()) return this.notificationService.show({ text: 'If you want to update your delivery address, you must select your delivery information.', type: 'error' });
    const data = this.setUpProfileDataForUpdate();
    this.profileService.put(data).pipe(
      tap(() => {
        if (this.isUpdatingAddress())
          this.handleChangeUpdateAddress(false);

        this.#getDeliveryZoneInfo();
      })
    ).subscribe();
  }

  #getDeliveryZoneInfo() {
    this.deliveriesService
      .getDeliveryZoneInfo()
      .pipe(
        tap(response => this.#validateTemporaryRoute(response.data))
      )
      .subscribe();
  }

  private isValidAddressUpdate() {
    if (!this.isUpdatingAddress()) return true;

    return !!this.selectedDeliveryDay() &&
      (
        (
          this.selectedDeliveryDay()?.allowTimeWindow &&
          !!this.selectedDeliveryTimeWindow()
        ) ||
        !this.selectedDeliveryDay()?.allowTimeWindow
      )
  }

  private setUpProfileDataForUpdate(): AccountPutPaylaod {
    const formValue = this.profileForm.getRawValue();
    let zoneId = null, zoneName = "", latitude = this.profileData()?.address?.latitude || 0, longitude = this.profileData()?.address?.longitude || 0;
    if (this.addressService.navegoAddress()) {
      ({ zoneId, zoneName, latitude, longitude } = this.addressService.navegoAddress() as NavegoAddressResponse);
    }

    return {
      accountInfo: {
        dietaryPreferences: formValue.dietaryInformation?.selectedProducts,
        email: formValue.email,
        firstName: formValue.firstName,
        lastName: formValue.lastName,
        noteDriver: formValue.address?.deliveryNotes,
        phone: formValue.phone.replace(/\D/g, ''),
        birthday: formValue.birthday
      },
      address: {
        additional: formValue.address?.additional,
        city: formValue.address?.city,
        stateCode: formValue.address?.state,
        street: formValue.address?.street,
        zip: formValue.address?.zipCode,
        latitude,
        longitude,
        zoneName,
        zoneId: this.selectedMembership()?.id != 0 ? (this.selectedMembership().configuration?.requireAddress ? zoneId || '' : this.selectedPickupLocation().id) : this.profileData().address.zoneId
      },
      settings: {
        receiveNewsletter: formValue.settings.receiveNewsletter,
        receiveText: formValue.settings.receiveText,
        receiveTextDelivery: formValue.settings.receiveTextDelivery,
        referral: {
          source: formValue.howDidYouHear,
          additionalInfo: formValue.additionalInfo || null
        },
        delivery: this.selectedMembership()?.id ? {
          type: this.selectedMembership().id,
          dayId: this.selectedMembership().configuration?.requireAddress ? this.selectedDeliveryDay()?.id : this.selectedPickupDeliveryDay()?.id,
          timeWindowId: this.selectedMembership().configuration?.requireAddress ? (this.selectedDeliveryTimeWindow() === -1 ? null : this.selectedDeliveryTimeWindow()) : null
        } : {
          type: null,
          dayId: this.signalsStoreService.isAddressRequired() ? this.selectedDeliveryDay()?.id : this.selectedPickupDeliveryDay()?.id,
          timeWindowId: this.signalsStoreService.isAddressRequired() ? (this.selectedDeliveryTimeWindow() === -1 ? null : this.selectedDeliveryTimeWindow()) : null
        }
      }
    }
  }

  formatPhoneNumber() {
    const control = this.profileForm.get('phone');
    if (!control) return;
    let value = control.value;
    if (value.toString().includes('+')) {
      value = value.substring(value.length - 10);
    }
    let cleaned = value.replace(/\D/g, '');
    cleaned = cleaned.substring(0, 10);
    let formatted = '';
    for (let i = 0; i < cleaned.length; i++) {
      if (i === 3 || i === 6) {
        formatted += ' ';
      }
      formatted += cleaned[i];
    }
    control.setValue(formatted);
  }

  openModalChangeCredentials(isEmail: boolean = false) {
    this.modalService.openModal(ModalContentTypes.CHANGE_CREDENTIALS, {
      title: `Reset ${isEmail ? 'Email' : 'Password'}`,
      changeCredentials: {
        email: isEmail ? this.profileForm.get('email')?.value : ''
      }
    }).closed.subscribe((result: { getProfileData: boolean } | null) => {
      if (result?.getProfileData)
        this.getProfileData();
    })
  }

  changeAddress() {
    this.hasDeliveryAddressCoverage = signal(false);
  }

  onPlaceSelected(place: google.maps.places.PlaceResult) {
    this.zone.run(() => {
      const {
        city,
        stateCode,
        zipCode,
        name: street,
        latitude,
        longitude,
        formattedAddress,
        placeId
      } = this.addressService.setupDeliveryData(place);

      this.googleAddress.set({
        street,
        city,
        stateCode,
        zipCode,
        latitude,
        longitude,
        placeId,
      });

      const addressForm = this.profileForm.get('address');
      if (addressForm) {
        addressForm.get("street")?.setValue(place.name);
        addressForm.get("city")?.setValue(city);
        addressForm.get("state")?.setValue(stateCode);
        addressForm.get("zipCode")?.setValue(zipCode)
      }

      this.deliveryAddress = { ...this.deliveryAddress, ...this.googleAddress() };
      this.isGoogleAddressFilled.set(true);
      this.checkDeliveryAddressCoverage();
    });

  }

  checkDeliveryAddressCoverage() {
    const { street, city, stateCode, latitude, longitude } = this.googleAddress();
    const navegoAddressRequest = {
      street,
      city,
      state: stateCode,
      latitude,
      longitude,
    }

    this.addressService.checkDeliveryAddress(navegoAddressRequest as NavegoAddressRequest, false, false);
    this.hasDeliveryAddressCoverage = computed(() => {
      if (!!this.addressService.navegoAddress()) this.getDeliveryOptions();
      return !!this.addressService.navegoAddress();
    });
  }

  handleChangeUpdateAddress(restoreCurrentData: boolean = true) {

    if (this.isTemporaryRouteChange())
      return;

    const control = this.profileForm.get('address.street');
    if (!control) return;
    control.disabled ? this.clearAddressForm(control) : this.resetUpdateAddress(control, restoreCurrentData);
  }

  private clearAddressForm(control: AbstractControl) {
    control.enable();
    this.isUpdatingAddress.set(true);
    this.setValuesToAddressForm();
  }

  private resetUpdateAddress(control: AbstractControl, restoreCurrentData: boolean = true) {
    this.isGoogleAddressFilled.set(false);
    this.hasDeliveryAddressCoverage = signal(false);
    this.isUpdatingAddress.set(false);
    control.disable();
    if (restoreCurrentData)
      this.setValuesToAddressForm(this.currentDeliveryAddress);
  }

  private setValuesToAddressForm(values?: any) {
    const addressForm = this.profileForm.get('address');
    if (addressForm) {
      addressForm.setValue({
        "street": values?.address || '',
        "additional": values?.additionalAddress || '',
        "city": values?.city || '',
        "state": values?.state || '',
        "zipCode": values?.zipCode || '',
        "deliveryNotes": values?.deliveryNotes || ''
      })
    }
  }

  handleDietaryPreferencesChange(e: any, item: any) {
    if (!e) return;
    const control = this.profileForm.get('dietaryInformation.selectedProducts');
    if (!control) return;
    let current = control.value;
    if (e.checked) {
      current.push(item);
      control.setValue(current);
    } else {
      const index = current.findIndex((e: any) => e.id === item.id);
      if (index > -1) {
        current.splice(index, 1);
        control.setValue(current);
      }
    }
  }

  private getAccountSettings() {
    this.accountService.getAccountSettings().subscribe({
      next: (accountSettings: AccountSettings | null) => {
        if (accountSettings) {
          this.setUpAccountSettings(accountSettings);
        }
      }
    });

  }

  private setUpAccountSettings(accountSettings: AccountSettings) {
    // Get the selected restrictions:
    const restrictions = this.profileForm.get('dietaryInformation.selectedProducts')?.value;
    this.dietaryPreferences.set(accountSettings.dietaryPreferences?.map((preference: ArrayOptionsItem) => {

      let completed = (!!restrictions?.length && restrictions.some((e: any) => e.id === preference.id))
      return { ...preference, completed };
    }) || []);

    this.referralSource.set(accountSettings.references);
  }

  handleReferralSelectionChanges(id: string) {
    console.log('ID >>>', id);
  }

  setSelectedDeliveryDay(event: MatSelectChange) {
    const option = this.deliveryOptions.find(o => o.id === event.value);
    if (option) {
      this.selectedDeliveryDay.set(option);
      this.selectedDeliveryTimeWindow.set(null)
    }
  }

  private setUpTimeSet() {
    const selectedDay = this.selectedDeliveryDay();
    return (selectedDay && selectedDay.timeSet && selectedDay.timeSet.length) ? selectedDay.timeSet : [];
  }

  private getDeliveryOptions() {
    this.accountService.getDeliveryOptions(this.addressService.navegoAddress()?.zoneId).subscribe({ next: (options: DeliveryOptionsResponseItem[]) => this.deliveryOptions = options });
  }

  private validatSubmitButtonState() {
    return this.isUpdatingAddress() && (((!this.hasDeliveryAddressCoverage() && this.isGoogleAddressFilled()) || !this.isValidAddressUpdate()) || !this.profileForm.get('address')?.valid)
      || this.isWaitingForAPIResponse();
  }

  setUpSelectedMembership() {
    if (this.selectedMembership().configuration?.requireAddress) {
      if (this.selectedMembership().id !== this.currentCustomerMembershipType().id) {
        this.isUpdatingAddress.set(true);
        const control = this.profileForm.get('address.street');
        if (!control) return;
        this.clearAddressForm(control)
      }
      this.setUpGMapsAutocomplete();
    } else {
      this.getPickUpLocations(this.selectedMembership().id);
      this.isUpdatingAddress.set(false);
      const control = this.profileForm.get('address.street');
      if (!control) return;
      this.resetUpdateAddress(control, true);
    }
  }

  setSelectedPickupLocation(id: any) {
    const item = this.pickupLocationList().find(p => p.id === id);
    if (!item) return;
    this.selectedPickupLocation.set(item);
    this.pickupDeliveryDaysList.set(item.deliveryDays);
    this.selectedPickupDeliveryDay.set(item.deliveryDays[0]);
  }

  setSelectedPickupDeliveryDay(id: any) {
    const item = this.pickupDeliveryDaysList().find(d => d.id === id);
    if (!item) return;
    this.selectedPickupDeliveryDay.set(item);
  }

  private setUpGMapsAutocomplete() {
    setTimeout(() => {
      GoogleMapsUtils.initializeAutocomplete(this.loader, this.autocompleteInput.nativeElement, this.onPlaceSelected.bind(this));
    }, 1);
  }

  onDateChange(event: any) {
    const value: moment.Moment = event.value;
    const formattedBirthday: string = value.format('YYYY-MM-DD');
    this.profileForm.controls['birthday'].setValue(formattedBirthday);
  }

  #validateTemporaryRoute(deliveryInfo: DeliveryInformation) {
    const { temporaryChange } = deliveryInfo;
    this.isTemporaryRouteChange.set(!!temporaryChange?.active);

    if (this.isTemporaryRouteChange())
      this.temporaryRouteChangeMessage.set(temporaryChange?.message ?? '');
  }

  #setUpSubmitButtonText() {
    return this.isWaitingForAPIResponse() ? 'Updating...' : 'Save Changes'
  }
}
