import { Component, computed, inject } from '@angular/core';
import { SignalsStoreService } from '../signals-store.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { SidebarService } from './sidebar.service';
import { SidebarCheckList } from './sidebar.types';
import { ShopComponent } from '../../shop/shop.component';
import { CategorySelected } from '../types/common.types';
import { toObservable } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { ProductsService } from '../../product/products.service';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [MatCheckboxModule, FormsModule, CommonModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {

  //#region Properties

  sidebarService = inject(SidebarService);

  signalsStore = inject(SignalsStoreService);

  #productsService = inject(ProductsService);

  hasGlobalMessages = computed(() => !!this.signalsStore.globalMessages().length);

  //#endregion

  //#region Constructor

  constructor(public shopComponent: ShopComponent) {

    toObservable(this.signalsStore.categoryInViewPort)
      .subscribe(obs => this.#changeCategoryInViewPort(obs));
  }

  //#endregion

  //#region Methods

  #changeCategoryInViewPort(obj: CategorySelected | null) {

    const { categoryId, subCategoryId, subCategorySonId } = obj ?? {};

    if (!categoryId || !subCategoryId)
      return;

    this.#resetOpenDetails();
    this.#selectSubCategory([subCategoryId, subCategorySonId ?? 0], this.sidebarService.sidebar());
  }

  #selectSubCategory(ids: number[], array: SidebarCheckList[]) {

    const id = ids.shift();

    const item = array.find(x => x.id === id);

    if (!item)
      return;

    item.checked = true;

    if (!item.sublist?.length)
      return;

    this.#selectSubCategory(ids, item.sublist);
  }

  #resetOpenDetails() {

    this.sidebarService
      .sidebar()
      .forEach(x => {
        x.checked = false;
        x.sublist?.forEach(y => y.checked = false);
      })
  }

  goToShopSection(item: SidebarCheckList, event?: Event) {
    this.#productsService.goToSection(item, event);
  }

  //#endregion
}
